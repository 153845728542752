/* Overwrite all MathQuill classes and design */

/* Main editable math field */
.mq-editable-field {
    border: 2px solid #ff5733;  /* Custom border */
    padding: 10px;  /* Custom padding */
    font-family: 'Arial', sans-serif;  /* Custom font */
    font-size: 18px;  /* Larger font size */
    color: #333;  /* Dark text color */
    background-color: #f9f9f9;  /* Light background */
    border-radius: 6px;  /* Rounded corners */
    width: 100%;
  }
  
  /* Root block for equations */
  .mq-root-block {
    width: 100%;
    height: 100%;
    padding: 4px;
    background-color: #ffffff;
    color: #2c3e50;
    border: 2px solid #3498db;
    border-radius: 5px;
  }
  
  /* Styling square roots */
  .mq-sqrt-prefix {
    font-size: 20px;  /* Custom font size for the sqrt symbol */
    color: #e74c3c;  /* Red color for square root */
    font-weight: bold;
  }
  
  /* Styling fractions */
  .mq-fraction {
    border-radius: 4px;
    padding: 4px;
    margin: 0 5px;
  }
  
  .mq-numerator {
    font-size: 16px;  /* Custom font size for numerator */
    color: #2980b9;  /* Blue color for numerator */
  }
  
  .mq-denominator {
    font-size: 16px;  /* Custom font size for denominator */
    color: #c0392b;  /* Red color for denominator */
  }
  
  /* Styling exponents and subscripts */
  .mq-sup {
    font-size: 14px;
    color: #8e44ad;  /* Purple color for superscripts */
  }
  
  .mq-sub {
    font-size: 14px;
    color: #f39c12;  /* Orange color for subscripts */
  }
  
  /* Styling parenthesis */
  .mq-paren {
    font-size: 24px;  /* Larger font for parentheses */
    color: #2ecc71;  /* Green color for parentheses */
  }
  
  /* Styling radicals */
  .mq-root {
    font-size: 20px;
    color: #3498db;  /* Custom color for radicals */
  }
  
  /* Placeholder for empty fields */
  .mq-empty {
    border: 1px solid #000000;  /* Dashed border for empty fields */
    background-color: #f3e5f5;
    padding: 4px;
    margin: 1px;
    border-radius: 4px;
  }
  
  /* Styling for operators */
  .mq-operator-name {
    color: #e67e22;  /* Orange color for operators like +, -, etc. */
    font-weight: bold;
  }
  
  /* Custom styling for the cursor */
  .mq-cursor {
    border-left: 2px solid #16a085;
    animation: blink 1s step-end infinite;  /* Custom blinking effect */
  }
  
  /* Blinking cursor animation */
  @keyframes blink {
    from, to {
      border-color: transparent;
    }
    50% {
      border-color: #16a085;
    }
  }




  


  .math-editor-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .math-icons {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .btn {
    background-color: #4A90E2;
    color: white;
    padding: 8px 12px;
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
  }
  
  .rendered-math {
    margin-top: 20px;
    padding: 10px;
    width: 100%;
    border: 2px solid #4A90E2;
    border-radius: 5px;
    background-color: #fff;
    min-height: 100px;
    font-size: 20px;
    color: #2c3e50;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  /* Fraction styling */
  .fraction {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    position: relative;
  }
  
  .numerator,
  .denominator {
    width: 30px;
    text-align: center;
    font-size: 16px;
    border: 1px solid #3498db;
    border-radius: 3px;
  }
  
  .frac-line {
    height: 2px;
    width: 30px;
    background-color: black;
    margin: -2px 0;
  }
  
  /* Square root styling */
  .sqrt {
    display: inline-flex;
    align-items: center;
    font-size: 20px;
  }
  
  .radical {
    width: 40px;
    border: 1px solid #3498db;
    border-radius: 3px;
    font-size: 16px;
  }
  
  /* Nested Equation styling */
  .nested-equation {
    margin: 10px;
  }
  
  .inner-equation {
    width: 100px;
    font-size: 16px;
    border: 1px solid #3498db;
    border-radius: 3px;
  }
  
  